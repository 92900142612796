import { Component, For, Show } from "solid-js";

import { styled } from "#style/jsx";
import { css } from "#style/css";
import { useTranslation } from "#root/domain/i18n";

import { Field } from "./Field";
import { Typography } from "./Typography";

type SelectItem = {
  label: string;
  value: string;
};

type SelectFieldProps = {
  label?: string;
  name?: string;
  optional?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  values: SelectItem[];
};

export const SelectField: Component<SelectFieldProps> = (
  props: SelectFieldProps,
) => {
  const { t } = useTranslation();

  const inputId = () => props.name + "_id_select";

  return (
    <Field.Container>
      <Show when={props.label}>
        <Field.Label for={inputId()}>
          {props.label}
          <Show when={props.optional}>
            <Typography
              tag="span"
              textStyle="xxsmallImportant"
              class={css({ color: "whiteSmoke.500" })}
            >
              {t("common.form.optional")}
            </Typography>
          </Show>
        </Field.Label>
      </Show>
      <styled.div
        position="relative"
        w="full"
        _after={{
          pointerEvents: "none",
          position: "absolute",
          content: "''",
          borderLeft: "6px solid transparent",
          borderRight: "6px solid transparent",
          borderTop: "6px solid black",
          top: "50%",
          right: "4",
          transform: "translateY(-50%)",
        }}
      >
        <styled.select
          id={inputId()}
          aria-disabled={props.disabled}
          name={props.name}
          onChange={(event) => props.onChange?.(event.target.value)}
          disabled={props.disabled}
          appearance="none"
          textStyle="xsmall"
          w="full"
          px="4"
          py="2"
          borderRadius="sm"
          border="thin solid"
          color="black"
          background="white"
          _focus={{
            outlineColor: "yellow",
          }}
          _hover={{
            borderColor: "yellow",
            _focus: {
              outlineColor: "yellow",
            },
          }}
          _disabled={{
            backgroundColor: "lightGray",
            color: "mediumGray",
          }}
        >
          <For each={props.values}>
            {(item) => (
              <option value={item.value} selected={props.value === item.value}>
                {item.label}
              </option>
            )}
          </For>
        </styled.select>
      </styled.div>
    </Field.Container>
  );
};
