import { useLocation } from "@solidjs/router";

import { styled, Box, VStack } from "#style/jsx";

import { AppPageContainer } from "#root/components/AppPageContainer";
import { AppDashboardHeader } from "#root/components/AppDashboardHeader";
import { useTranslation } from "#root/domain/i18n";
import { Typography } from "#root/components/Typography";
import { SelectField } from "#root/components/SelectField";

export default function Settings() {
  const { t, locale } = useTranslation();
  const location = useLocation();

  function changeLanguage(language: string) {
    const [_, _lang, ...rest] = location.pathname.split("/");
    const destinationURL = `/${language}/${rest.join("/")}`;
    window.location.href = destinationURL;
  }

  return (
    <AppPageContainer>
      <AppDashboardHeader pageTitle={t("settings.pageTitle")} />
      <Box mt="10" maxWidth="1100px">
        <VStack
          w="full"
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="4"
        >
          <Typography textStyle="heading4">
            {t("settings.languageSection.title")}
          </Typography>
          <styled.blockquote
            py="2"
            px="4"
            borderLeft="5px solid"
            borderLeftColor="mediumGray"
            backgroundColor="darkGray"
            w="fit-content"
            maxW="full"
            color="linen"
          >
            <Typography textStyle="xsmall">
              {t("settings.languageSection.helpText")}
            </Typography>
          </styled.blockquote>
          <VStack maxW="400px" w="full">
            <SelectField
              name="language"
              label={t("settings.languageSection.label")}
              values={[
                { value: "fr", label: "Français" },
                { value: "en", label: "English" },
              ]}
              value={locale}
              onChange={changeLanguage}
            />
          </VStack>
        </VStack>
      </Box>
    </AppPageContainer>
  );
}
